<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link
          class="text-decoration--none"
          :to="{ name: 'organisations' }"
        >
          <div class="div-missions">Organisation</div>
        </router-link>
        <v-icon>{{ icons.arrowRight }}</v-icon>
        {{ organisation ? organisation.name : '' }}
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="organisation">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ organisation.name }}
      </div>

      <div class="d-flex my-6">
        <v-tabs hide-slider class="organisation-detail-tabs" color="yellow">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">{{
            item.tab
          }}</v-tab>
          <v-tab-item><OrganisationForm /></v-tab-item>
          <v-tab-item><UsersList /></v-tab-item>
        </v-tabs>
      </div>
    </div>

    <v-overlay absolute :value="isLoading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UsersList from './components/UsersList'
import OrganisationForm from './components/OrganisationForm'

export default {
  name: 'OrganisationDetails',

  components: {
    AppBarNavIcon,
    UsersList,
    OrganisationForm,
  },

  data() {
    return {
      icons: {
        arrowRight: mdiChevronRight,
      },
      isLoading: false,
      tabItems: [{ tab: 'Info' }, { tab: 'Users' }],
    }
  },

  computed: {
    ...mapState({
      organisation: (state) => state.organisation.organisationDetails,
    }),
  },

  methods: {
    ...mapActions({
      getOrganisationDetails: 'organisation/getOrganisationDetails',
    }),

    ...mapMutations({
      clearOrganisationDetails: 'organisation/clearOrganisationDetails',
      clearUserList: 'user/clearUserList',
    }),

    async getOrganisation() {
      this.isLoading = true
      await this.getOrganisationDetails(this.$route.params.id)
      this.isLoading = false
    },
  },

  created() {
    this.getOrganisation()
  },

  destroyed() {
    this.clearOrganisationDetails()
    this.clearUserList()
  },

  watch: {
    $route() {
      this.getOrganisation()
    },
  },
}
</script>

<style lang="scss" scoped></style>
